import React from 'react';
import mainImage from '../../Assets/images/Our sister companies image.webp';
import { MainImage } from 'gatsby-plugin-image';

const OurPartnerCompanies  = () => {
  return (
    <main className="container pb-10 flex items-center justify-center">
      <div className=" rounded-[30px] shadow-lg px-10 py-8 flex items-center justify-center flex-col ">
        <h1 className="text-primary font-normal text-xl lg:text-3xl text-center mb-8 ">
          Our Partner Companies
        </h1>
        <div class="w-full">
          <MainImage
            src={mainImage}
            alt="image"
            width={100}
            height={100}
            loading="lazy"
            className="object-contain w-full h-auto max-w-[400px]"
          />
        </div>
      </div>
    </main>
  );
};

export default OurPartnerCompanies;
